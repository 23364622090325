import * as React from "react";
import { UniversalLink } from "../global";
import { ButtonLink } from "../atoms";

const BecomeASponsor = () => {
  return (
    <section className="container mx-auto section text-center px-2">
      <h2 className={"h2"}>
        Interested in sponsoring?{" "}
        <span role={"img"} aria-label={"sunglasses-emoji"}>
          {" "}
          😎️{" "}
        </span>
      </h2>
      <div className={"text-center max-w-xl mx-auto space-y-4 mt-2"}>
        <p>
          Help us improve our community while gaining a little recognition for
          your business or organization! The revenue received from a sponsorship
          goes right back into the community and the I{" "}
          <span role={"img"} aria-label={"heart-emoji"}>
            ❤️{" "}
          </span>
          ️ BD movement.
        </p>
        <p>You will receive:</p>
        <ol className={"list-decimal pl-6 text-left max-w-md mx-auto"}>
          <li>A window cling to hang at your business or organization</li>
          <li>
            Your business or organization’s name on the I{" "}
            <span role={"img"} aria-label={"heart-emoji"}>
              ❤️{" "}
            </span>
            ️ BD T-shirt
          </li>
          <li>Recognition on the website</li>
        </ol>
        <ButtonLink url={"/contact"}>Become a sponsor</ButtonLink>
        <p className={"text-sm"}>
          Please{" "}
          <UniversalLink url={"/contact"} className={"border-b border-primary"}>
            {" "}
            contact us{" "}
          </UniversalLink>{" "}
          if you have any questions.
        </p>
      </div>
    </section>
  );
};

export default BecomeASponsor;
