import * as React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { graphql } from "gatsby";
import { ButtonLink } from "../components/atoms";
import { css } from "@emotion/core";
import BecomeASponsor from "../components/sections/BecomeASponsor";
import Img from "gatsby-image";

const SponsorsPage = ({ data }) => {
  const sponsors = data.allFile.edges;
  return (
    <Layout>
      <SEO title={"Sponsors"} />
      <section className={"section container mx-auto"}>
        <h1 className={"h1 text-center"}>
          Our awesome sponsor list
          <span role={"img"} aria-label={"heart-emoji"}>
            {" "}
            ❤️{" "}
          </span>
        </h1>
        <div className={"flex flex-wrap mt-16"}>
          {sponsors.map(({ node }) => (
            <SponsorCard
              title={node.childMarkdownRemark.frontmatter.title}
              url={node.childMarkdownRemark.frontmatter.url}
              logo={node.childMarkdownRemark.frontmatter.logo}
              key={node.id}
            />
          ))}
        </div>
      </section>
      <BecomeASponsor />
      <div className={"pb-16"} />
    </Layout>
  );
};

const SponsorCard = ({ title, url, logo }) => {
  return (
    <div className={"w-full md:w-1/2 p-6 text-center md:text-left"}>
      <div className="h-full md:flex border border-gray-200 bg-white">
        <div className="w-full md:w-auto flex-grow p-4 rounded-lg rounded-r-none">
          <Img
            css={css`
              max-height: 5rem;
              height: auto;
              max-width: 90%;
              margin: 0 auto;
            `}
            fluid={logo.childImageSharp.fluid}
            alt="bag"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
        <div className="w-full md:w-2/5 flex flex-col px-4 py-4 rounded-lg">
          <h2 className="text-lg md:text-xl font-semibold tracking-wide leading-none md:text-right">
            {title}
          </h2>
          {/*<ul className="flex-grow text-sm text-gray-700 mt-4">*/}
          {/*  <li>Location</li>*/}
          {/*  <li>???</li>*/}
          {/*</ul>*/}
          <div className="flex items-center justify-end mt-auto pt-4">
            <ButtonLink url={url} alt className={"w-full md:w-auto"}>
              Website
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
// const SponsorCard = ({ title, url, logo }) => {
//   return (
//     <div className={"w-full md:w-1/2 p-6 text-center md:text-left"}>
//       <div className="h-full md:flex shadow-lg rounded-lg bg-white" css={css``}>
//         <div className="w-full md:w-2/5 p-4 flex items-center justify-center rounded-lg rounded-r-none">
//           <img
//             css={css`
//               max-height: 6rem;
//               height: auto;
//             `}
//             src={logo.childImageSharp.fluid.src}
//             alt="bag"
//           />
//         </div>
//         <div className="w-full md:w-3/5 flex flex-col px-4 py-4 rounded-lg">
//           <h2 className="text-lg font-semibold tracking-wide leading-none text-right">
//             {title}
//           </h2>
//           {/*<ul className="flex-grow text-sm text-gray-700 mt-4">*/}
//           {/*  <li>Location</li>*/}
//           {/*  <li>???</li>*/}
//           {/*</ul>*/}
//           <div className="flex items-center justify-end mt-auto pt-4">
//             <ButtonLink url={url} className={""}>
//               Website
//             </ButtonLink>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const sponsorsPageQuery = graphql`
  query SponsorsPageQuery {
    allFile(filter: { relativeDirectory: { eq: "sponsors" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              title
              url
              logo {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SponsorsPage;
